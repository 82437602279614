import {
  BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { getXUseCaseHeaderSafety } from './utils';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const veevaBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
    > = fetchBaseQuery({
      baseUrl: `${baseUrl}/api/v1`,
      prepareHeaders: (headers, { getState }) => {
        const { veevaToken } = (getState() as RootState).session;
        const { userID, userName } = (getState() as RootState).config;
        const xUseCase = getXUseCaseHeaderSafety(getState() as RootState);
        if (veevaToken) {
          headers.set('Authorization', veevaToken);
          headers.set('X-VEEVA-USER-ID', userID || '');
          headers.set('X-VEEVA-USER-EMAIL', userName || '');
          headers.set('X-UseCase', xUseCase);
        }
        return headers;
      },
    });
