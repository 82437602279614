import React from 'react';
import AppHeader from '../header/Header';

type Props = {
  children: string | React.ReactNode
}

export default function BaseLayout({ children }: Props) {
  return (
    <div style={{
      height: 'calc(100svh)', display: 'flex', minHeight: 0, flexDirection: 'column',
    }}
    >
      <div style={{ maxHeight: '96px' }}>
        <AppHeader />
      </div>
      {children}
    </div>
  );
}
