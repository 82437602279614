import { createSlice } from '@reduxjs/toolkit';
import { executeVeevaObjectThunk } from '../../../features/getVeevaObject/model/getVeevaObject';

type ObjectStatus = 'idle' | 'pending' | 'succeeded' | 'failed'

type ObjectState = {
    recordName: string,
    batchId?: string
    error?: string
    status: ObjectStatus
}

export const initialState: ObjectState = {
  recordName: '',
  batchId: '',
  error: '',
  status: 'idle',
};

export const veevaOjectSlice = createSlice({
  name: 'veevaObject',
  initialState,
  reducers: {
    clearVeevaErrors: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      executeVeevaObjectThunk.fulfilled,
      (state, { payload }) => {
        state.recordName = payload?.recordName;
        state.batchId = payload?.batchId;
        state.status = 'succeeded';
      },
    );
    builder.addCase(
      executeVeevaObjectThunk.pending,
      (state) => {
        state.status = 'pending';
        state.error = '';
      },
    );
    builder.addCase(
      executeVeevaObjectThunk.rejected,
      (state) => {
        state.status = 'failed';

        // @ts-ignore
        state.error = 'Failed to load Veeva object details, re-open application';
      },
    );
  },

});

// @ts-ignore
export const getVeevaObject = (state: RootState) => state.veevaObject;

export const { clearVeevaErrors } = veevaOjectSlice.actions;
export default veevaOjectSlice.reducer;
