type AnyFunction = (...args: any[]) => any;

type FirstFunction<F extends AnyFunction[]> =
  F extends [infer First, ...any[]] ? First : () => never;

type ReturnTypeOfFirst<F extends AnyFunction[]> = ReturnType<FirstFunction<F>>;

type Compose = <F extends AnyFunction[]>(
  ...fns: F
) => (...params: Parameters<FirstFunction<F>>) => ReturnTypeOfFirst<F>;

export const compose: Compose = (...fns) => (...params) =>
  fns.reduceRight((acc: any[], fn) => [fn(...acc)], params)[0];
