export const ENDPOINTS = {
  GET_BATCHES: '/batches/batch/:num/:useCase',
  GET_BATCHES_WHERE_FROM: '/batches/whereFrom/:num',
  GET_BATCHES_WHERE_USED: '/batches/whereUsed/:num/:useCase',
  GET_AUTOCOMPLETE_BATCH: '/autocomplete/batch/:num/:useCase',
  GET_AUTOCOMPLETE_WHERE_FROM: '/autocomplete/batch/:num/:useCase',
  GET_AUTOCOMPLETE_WHERE_USED: '/autocomplete/batch/:num/:useCase',
  GET_GENEALOGY_WHERE_FROM: '/batches/whereFrom/:num/genealogy',
  GET_GENEALOGY_WHERE_USED: '/batches/whereUsed/:num/genealogy/:useCase',
  GET_GENEALOGY_WHERE_FROM_FILTERS: '/batches/whereFrom/:num/genealogy/filter/:filter',
  GET_GENEALOGY_FILTERS: '/batches/:flow/:num/genealogy/filter/:filter/:useCase',
  GET_GENEALOGY_WHERE_USED_FILTERS: '/batches/whereUsed/:num/genealogy/filter/:filter/:useCase',

  SAVE_BATCH: '/batches',
  SAVE_BATCH_WHERE_FROM: '/batches/whereFrom/genealogy',
  SAVE_BATCH_WHERE_USED: '/batches/:useCase/whereUsed/genealogy',

  SAVE_SAFATY_BATCH_DEVICE: '/batches/device/:useCase/safety',
  SAVE_SAFATY_BATCH_DRUGS: '/batches/drugs/:useCase/safety',

  GET_VEEVA_OBJECT: '/veeva/:objectName/:recordNumber/:useCase',
} as const;

export const getUrl = (
  url: (typeof ENDPOINTS)[keyof typeof ENDPOINTS],
  mapping: Record<string, string | undefined>,
) => `/${url
  .split('/')
  .map((part) => (part.startsWith(':') ? mapping[part.slice(1)] || '' : part))
  .filter(Boolean)
  .join('/')}/`;
