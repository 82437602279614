import { veevaAPI } from '../../../shared/api/baseAPI';
import { ENDPOINTS, getUrl } from '../../../shared/config/endpoints';

export const veevaApi = veevaAPI.injectEndpoints({
  endpoints: (builder) => ({
    object: builder.query<unknown,
          { recordNumber: string, objectName: string, useCase?: string }>({
            query: ({ recordNumber, objectName, useCase = '' }) => ({
              url: getUrl(ENDPOINTS.GET_VEEVA_OBJECT, { objectName, recordNumber, useCase }),
            }),
            transformResponse(data: {nameV: string, reportedBatchNumberC: string })
                : {recordName: string, batchId: string} {
              return {
                recordName: data?.nameV || '',
                batchId: (data.reportedBatchNumberC || ''),
              };
            },
            forceRefetch(): boolean {
              return true;
            },
          }),
  }),
});
