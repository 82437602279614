import { createAsyncThunk } from '@reduxjs/toolkit';
import { veevaApi } from '../../../entyties/veevaObject/api/veevaObjectApi';
import { setProperty } from '../../../entyties/config/model/Config';

export const executeVeevaObjectThunk = createAsyncThunk<
    any,
    Parameters<typeof veevaApi.endpoints.object.initiate>['0'],
    { state: RootState }
    >(
      'batches/executeVeevaObject',
      async ({ recordNumber, objectName, useCase }, { dispatch }) => {
        const res = await dispatch(veevaApi.endpoints.object
          .initiate({ recordNumber, objectName, useCase }))
          .unwrap();
        // @ts-ignore
        dispatch(setProperty({ key: 'num', value: res.batchId }));
        return res;
      },
    );
