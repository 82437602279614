import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setProperty } from '../../entyties/config/model/Config';
import { readQueryParameters } from '../../shared/utils/readQueryParameters';
import { setVeevaToken } from '../../entyties/veevaSession/model/VeevaSession';

export default function withAuth(component: (props: any) => React.ReactNode) {
  return function _(props: any) {
    const {
      token,
      key: keyValue,
      recordNum,
      recordName,
      userName,
      userID,
      batchValidation,
    } = readQueryParameters();

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setVeevaToken({ token }));
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search.slice(1));
      params.delete('token');
      const remove = process.env.REACT_APP_USE_REMOVE_TOKEN_FROM_URL === 'true';
      if (remove) {
        window.history.replaceState({}, '', `${window.location.pathname}?${params}${window.location.hash}`);
      }

      const values = [
        { key: 'key', value: keyValue },
        { key: 'recordNum', value: recordNum },
        { key: 'userName', value: userName },
        { key: 'userID', value: userID },
        { key: 'recordName', value: recordName },
        { key: 'batchValidation', value: batchValidation === 'true' },
      ] as const;

      values.forEach((value) => dispatch(setProperty(value)));
    }, []);

    if (!token) {
      return (
        <div data-testid="not-authorized-message">
          You have accessed this search tool from an incorrect location.
          {' '}
          Please access this search tool only from the authorized Veeva environment.
        </div>
      );
    }
    return (
      <div>
        {component(props)}
      </div>
    );
  };
}
