import React, { createContext, useEffect } from 'react';
import { notification } from 'antd';

export const NotificationContext = createContext<
    {
        api?: ReturnType<typeof notification.useNotification>[0]
    }
>({ api: undefined });

export default function withNotifications(
  component: (props: any) => React.ReactNode,
) {
  return function _(props: any) {
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
      setTimeout(() => {
        api.error({
          message: 'Session expired',
        });
      }, 1000 * 60 * 60);
    }, []);
    return (
      <div>
        <NotificationContext.Provider value={{ api }}>
          {contextHolder}
          {component(props)}
        </NotificationContext.Provider>
      </div>
    );
  };
}
