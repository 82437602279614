import { createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { Batch } from '../../../entyties/batch/model/types';
import { batchesApi } from '../../../entyties/batch/api/batchesApi';

const handler = async (
  batchIdSubstring: string,
  flow: 'autocomplete' | 'autocompleteWhereUsed' | 'autocompleteWhereFrom',
  { dispatch, useCase }: { dispatch: ThunkDispatch<any, any, any>, useCase: string },
) => {
  try {
    const res = await dispatch(
      batchesApi.endpoints[flow].initiate({ sub: batchIdSubstring, useCase: useCase === 'SAFETY' ? 'safety' : '' }),
    ).unwrap();
    return res;
  } catch {
    return [];
  }
};

export const getAutocompleteThunk = createAsyncThunk<
    Array<string>,
    {
        batchIdSubstring: Batch['batchId'],
        flow: 'autocomplete' | 'autocompleteWhereUsed' | 'autocompleteWhereFrom',
    },
    { state: RootState }
    >(
      'batches/getAutoComplete',
      ({ batchIdSubstring, flow }, { dispatch, getState }) => {
        const state = getState();
        const useCase = state.config.key || '';
        return handler(batchIdSubstring, flow, { dispatch, useCase });
      },
    );
