import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { BrowserRouter } from 'react-router-dom';

export default function withRouter(component: (props: any) => React.ReactNode) {
  return function _(props: any) {
    const fallback = (
      <div style={{ display: 'flex', height: '100svh', width: '100svw' }}>
        <Spin delay={300} style={{ margin: 'auto' }} className="overlay" size="large" />
      </div>
    );
    return (
      <BrowserRouter>
        <Suspense fallback={fallback}>
          {component(props)}
        </Suspense>
      </BrowserRouter>
    );
  };
}
