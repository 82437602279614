import { compose } from 'shared/utils/compose';
import withAuth from './withAuth';
import withRouter from './withRouter';
import withNotifications from './withNotifications';

// compose works from last to first, please be careful
export const withProviders = compose(
  withAuth,
  withRouter,
  withNotifications,
);
