import { createSlice } from '@reduxjs/toolkit';
import {
  WhereFrom,
  WhereUsed,
  Batch,
  Items,
} from './types';
import { executeBatchSearchThunk, executeBatchWhereFromSearchThunk, executeBatchWhereUsedSearchThunk } from '../../../features/batchSearch/model/executeBatchSearch';
import { getAutocompleteThunk } from '../../../features/getAutoComplete/model/getAutoComplete';
import { getWhereFromGenealogyDataThunk, getWhereUsedGenealogyDataThunk } from '../../../features/getGenealogyData/model/getGenealogyData';

type BatchStatus = 'idle' | 'pending' | 'succeeded' | 'failed'
type BatchesSliceState = {
    items: Items<Batch>,
    status: BatchStatus
    autocompleteStatus: BatchStatus
    autocomplete: Array<Batch['batchId']>
    errorMessage: string
    errorStatus: null | number | string
    genealogyData: Items<WhereFrom.BatchGenealogy | WhereUsed.BatchGenealogy>
    genealogyStatus: BatchStatus
    selectedBatchForGenealogy: Batch | null
}

export const defaultItems = { body: [], page: 1, totalRowsCount: 0 };
export const initialState: BatchesSliceState = {
  items: defaultItems,
  autocomplete: [],
  autocompleteStatus: 'idle',
  status: 'idle',
  errorMessage: '',
  errorStatus: null,
  selectedBatchForGenealogy: null,
  genealogyData: defaultItems,
  genealogyStatus: 'idle',
};

const ERROR_MESSAGES = {
  401: 'User is not authorized',
  404: 'The batch you searched on not found. Please verify the batch number or try wildcard searching. '
      + 'Remember the batch data source is refreshed every 24 hours.',
  400: 'The batch you searched on not found. Please verify the batch number or try wildcard searching. '
      + 'Remember the batch data source is refreshed every 24 hours.',
  FETCH_ERROR: 'System connectivity error',
};

export const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    clearBatchErrors: (state) => {
      state.errorMessage = '';
    },
    clearItems: (state) => {
      state.items = defaultItems;
    },
    clearAutocomplete: (state) => {
      state.autocomplete = [];
    },
    setSelectedBatch: (state, { payload }) => {
      state.selectedBatchForGenealogy = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      executeBatchSearchThunk.fulfilled,
      (state, { payload }) => {
        state.items = (payload as any);
        state.status = 'succeeded';
      },
    );
    builder.addCase(
      executeBatchSearchThunk.pending,
      (state) => {
        state.status = 'pending';
        state.errorStatus = null;
        state.errorMessage = '';
      },
    );
    builder.addCase(
      executeBatchSearchThunk.rejected,
      (state, { payload }) => {
        state.status = 'failed';
        // @ts-ignore
        state.items = defaultItems;
        // @ts-ignore
        state.errorStatus = payload?.originalStatus || null;
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload?.originalStatus || payload?.status] || payload?.data || payload?.error || '';
      },
    );
    builder.addCase(
      getAutocompleteThunk.fulfilled,
      (state, data) => {
        state.autocomplete = (data.payload as unknown as string[]) || [];
        state.autocompleteStatus = 'succeeded';
      },
    );
    builder.addCase(
      getAutocompleteThunk.pending,
      (state) => {
        state.autocompleteStatus = 'pending';
        state.errorStatus = null;
        state.errorStatus = null;
      },
    );
    builder.addCase(
      getAutocompleteThunk.rejected,
      (state) => {
        state.autocompleteStatus = 'succeeded';
      },
    );

    builder.addCase(
      getWhereFromGenealogyDataThunk.pending,
      (state) => {
        state.genealogyStatus = 'pending';
        state.errorStatus = null;
        state.errorMessage = '';
      },
    );

    builder.addCase(
      getWhereFromGenealogyDataThunk.rejected,
      (state, { payload }) => {
        state.genealogyStatus = 'failed';
        // @ts-ignore
        state.genealogyData = [];
        // @ts-ignore
        state.errorStatus = payload?.originalStatus || null;
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload?.originalStatus || payload?.status] || payload?.data || payload?.error || '';
      },
    );

    builder.addCase(getWhereFromGenealogyDataThunk.fulfilled, (state, { payload }) => {
      // @ts-ignore
      state.genealogyData = payload;
      state.genealogyStatus = 'succeeded';
    });

    builder.addCase(
      getWhereUsedGenealogyDataThunk.pending,
      (state) => {
        state.genealogyStatus = 'pending';
        state.errorStatus = null;
        state.errorMessage = '';
      },
    );

    builder.addCase(getWhereUsedGenealogyDataThunk.rejected, (state, { payload }) => {
      state.genealogyStatus = 'failed';
      // @ts-ignore
      state.genealogyData = [];
      // @ts-ignore
      state.errorStatus = payload?.originalStatus || null;
      // @ts-ignore
      state.errorMessage = ERROR_MESSAGES[payload?.originalStatus || payload?.status] || payload?.data || payload?.error || '';
    });
    builder.addCase(
      getWhereUsedGenealogyDataThunk.fulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.genealogyData = payload;
        state.genealogyStatus = 'succeeded';
      },
    );
    builder.addCase(
      executeBatchWhereFromSearchThunk.fulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.items = payload;
        state.status = 'succeeded';
      },
    );
    builder.addCase(
      executeBatchWhereFromSearchThunk.pending,
      (state) => {
        state.status = 'pending';
        state.errorStatus = null;
        state.errorMessage = '';
      },
    );
    builder.addCase(
      executeBatchWhereFromSearchThunk.rejected,
      (state, { payload }) => {
        state.status = 'failed';
        state.items = defaultItems;
        // @ts-ignore
        state.errorStatus = payload?.originalStatus || null;
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload?.originalStatus || payload?.status] || payload?.data || payload?.error || '';
      },
    );
    builder.addCase(
      executeBatchWhereUsedSearchThunk.fulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.items = payload;
        state.status = 'succeeded';
      },
    );
    builder.addCase(
      executeBatchWhereUsedSearchThunk.pending,
      (state) => {
        state.status = 'pending';
        state.errorStatus = null;
        state.errorMessage = '';
      },
    );
    builder.addCase(
      executeBatchWhereUsedSearchThunk.rejected,
      (state, action) => {
        const { payload, error } = action;
        state.status = 'failed';
        state.items = defaultItems;
        // @ts-ignore
        state.errorStatus = payload?.originalStatus || null;
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload?.originalStatus || payload?.status] || payload?.data || error?.message || '';
      },
    );
  },
});

export const getBatches = (state: RootState) => state.batches.items;
export const getStatus = (state: RootState) => state.batches.status;
export const getErrorMessage = (state: RootState) =>
  ({ errorMessage: state.batches.errorMessage, code: state.batches.errorStatus });
export const getAutocompleteState = (state: RootState) => state.batches.autocomplete;
export const getAutocompleteStatus = (state: RootState) => state.batches.autocompleteStatus;
export const getGenealogyStatus = (state: RootState) => state.batches.genealogyStatus;
export const getGenealogyData = (state: RootState) => state.batches.genealogyData;
export const getSelectedBatch = (state: RootState) => state.batches.selectedBatchForGenealogy;
export default batchesSlice.reducer;
export const {
  clearItems,
  clearAutocomplete,
  setSelectedBatch,
  clearBatchErrors,
} = batchesSlice.actions;
