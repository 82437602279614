import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const DEFAULT_TIMEOUT = 30 * 60 * 1000;

type SessionSliceState = {
    veevaToken?: string,
    isVeevaAuthorized: boolean,
    timeout: number
}

const initialState: SessionSliceState = {
  isVeevaAuthorized: false,
  veevaToken: '',
  timeout: DEFAULT_TIMEOUT,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setVeevaToken: (state, action: PayloadAction<{token: string}>) => {
      state.veevaToken = action.payload.token;
      state.isVeevaAuthorized = true;
    },
  },
});

export const selectIsAuthorized = (state: RootState) =>
  state.session.isVeevaAuthorized;

export const selectVeevaToken = (state: RootState) =>
  (state.session.isVeevaAuthorized ? state.session.veevaToken : null);

export const { setVeevaToken } = sessionSlice.actions;
