import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import routerConfig from '../shared/config/routerConfig';

const Main = lazy(() => import('./Main/ui'));
const Cart = lazy(() => import('./Cart/ui'));
const Genealogy = lazy(() => import('./Genealogy/ui'));

export default function Routing() {
  return (
    <Switch>
      <Route exact path={routerConfig.MAIN} component={Main} />
      <Route exact path={routerConfig.CART} component={Cart} />
      <Route exact path={routerConfig.GENEALOGY} component={Genealogy} />
      <Redirect to={routerConfig.MAIN} />
    </Switch>
  );
}
