export const SUFFIXES = {
  drugsValidation: 'DRUGS_VALIDATION',
  drugsGenealogy: 'DRUGS_GENEALOGY',
  devicesValidation: 'DEVICES_VALIDATION',
  devicesGenealogy: 'DEVICES_GENEALOGY',
};

export const drugs: string[] = ['case_product_dosage__v'];
export const devices: string[] = ['case_product__v'];

export const isDrugs = (state: RootState) => {
  return drugs.includes(state?.config?.recordName || '');
};

export const isDevices = (state: RootState) => {
  return devices.includes(state?.config?.recordName || '');
};

export const isValidation = (state: RootState) => {
  return state.config?.batchValidation;
};

export const isSafety = (state: RootState) => {
  const flow = state?.config?.key;
  return flow === 'SAFETY';
};

export const isBatchValidation = (state: RootState): boolean => {
  return !!state?.config?.batchValidation;
};

export const getBatchValidationKey = (state: RootState) => {
  if (isDevices(state)) return 'DeviceBatchValidation';
  return 'DrugsBatchValidation';
};

export const getBatchGenealogyKey = (state: RootState) => {
  if (isDevices(state)) return 'DeviceBatchWhereUsed';
  return 'DrugsBatchWhereUsed';
};

export const getXUseCaseHeaderSafety = (state: RootState): string => {
  if (!isSafety(state)) return state?.config?.key || '';
  if (isBatchValidation(state)) return getBatchValidationKey(state);
  return getBatchGenealogyKey(state);
};
